import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as googlee0llBhNF10Meta } from "/app/pages/login/google.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as remember_45meD6KPsX1agxMeta } from "/app/pages/login/remember-me.vue?macro=true";
import { default as authorizekSgZ0t1dLpMeta } from "/app/pages/oauth2/authorize.vue?macro=true";
import { default as revokeHcd8Oy0N9nMeta } from "/app/pages/oauth2/revoke.vue?macro=true";
import { default as indexLEgOslV9HiMeta } from "/app/pages/password-recovery/index.vue?macro=true";
import { default as set_45passwordzyE9ZChREzMeta } from "/app/pages/password-recovery/set-password.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login-google",
    path: "/login/google",
    component: () => import("/app/pages/login/google.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-remember-me",
    path: "/login/remember-me",
    component: () => import("/app/pages/login/remember-me.vue").then(m => m.default || m)
  },
  {
    name: "oauth2-authorize",
    path: "/oauth2/authorize",
    component: () => import("/app/pages/oauth2/authorize.vue").then(m => m.default || m)
  },
  {
    name: "oauth2-revoke",
    path: "/oauth2/revoke",
    component: () => import("/app/pages/oauth2/revoke.vue").then(m => m.default || m)
  },
  {
    name: "password-recovery",
    path: "/password-recovery",
    component: () => import("/app/pages/password-recovery/index.vue").then(m => m.default || m)
  },
  {
    name: "password-recovery-set-password",
    path: "/password-recovery/set-password",
    component: () => import("/app/pages/password-recovery/set-password.vue").then(m => m.default || m)
  }
]